import React from 'react';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

interface AddressProps {
  sx?: ThemeUIStyleObject;
}

export const Address = ({ sx }: AddressProps) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: ['center', 'flex-start'],
        textAlign: 'center',
        justifyContent: 'center',
        '*': { color: 'white' },
        ...sx,
      }}
    >
      <Text color="white" sx={{ fontWeight: 'bold' }}>
        Università della Svizzera Italiana
      </Text>
      <Text>Campus Est</Text>
      <Text>Via la Santa 1</Text>
      <Text>6962 Viganello</Text>
      <Text>Switzerland</Text>
    </Flex>
  );
};
