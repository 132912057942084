import React from 'react';
import { Grid } from 'theme-ui';
import { Speaker } from '../../../models/Speaker';
import { AbstractAndBio } from './speaker-item/AbstractAndBio';
import { ImageAndDetails } from './speaker-item/ImageAndDetails';

interface Props {
  speaker: Speaker;
  reverse?: boolean;
  imageOnly?: boolean;
}

export const SpeakerItem = ({
  speaker,
  reverse = false,
  imageOnly = false,
}: Props) => {
  const {
    name,
    image,
    position,
    website,
    affiliation,
    bio,
    talkTitle,
    talkVideoLink,
    talkAbstract,
  } = speaker;

  const imageAndDetailsProps = {
    name,
    image,
    position,
    website,
    affiliation,
  };
  const abstractAndBioProps = {
    bio,
    talkTitle,
    talkVideoLink,
    talkAbstract,
  };

  return imageOnly ? (
    <ImageAndDetails {...imageAndDetailsProps} />
  ) : (
    <Grid
      sx={{
        gridTemplateAreas: [null, null, null, '"first second"'],
      }}
    >
      <ImageAndDetails
        {...imageAndDetailsProps}
        sxProps={{
          gridArea: reverse
            ? [null, null, null, 'second']
            : [null, null, null, 'first'],
        }}
      />
      <AbstractAndBio
        {...abstractAndBioProps}
        sxProps={{
          pl: reverse ? null : [0, 2, 5],
          pr: reverse ? [0, 2, 5] : null,
          gridArea: reverse
            ? [null, null, null, 'first']
            : [null, null, null, 'second'],
        }}
      />
    </Grid>
  );
};
