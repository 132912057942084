import React from 'react';
import { Paragraph } from 'theme-ui';
import { Link } from '../commons/Link';
import { PageSection } from '../section-templates/PageSection';

export const Event = () => {
  return (
    <PageSection>
      <PageSection.Title>SI Summit - 1st Edition</PageSection.Title>
      <PageSection.Content>
        <Paragraph>
          On 17 February 2022, USI's{' '}
          <Link external={true} href="https://si.usi.ch" target="_blank">
            Software Institute
          </Link>{' '}
          will host the first SI Summit: a day of talks by prominent researchers
          and practitioners in the domain of software in a broad sense.
        </Paragraph>
        <Paragraph>
          The first edition of the Summit will feature talks by four leading
          experts in a wide range of topics: from software development and
          engineering and software quality, to distributed systems and
          programming languages, to the impact of software on society: privacy,
          democracy, and transparency in the digital world.
        </Paragraph>
        <Paragraph>
          The Summit will be held online as a fully virtual event.
        </Paragraph>
      </PageSection.Content>
    </PageSection>
  );
};
