
export enum SESSION {
    MORNING = 'morning',
    AFTERNONOON = 'afternoon',
}
export interface ProgramItem {
    time: string;
    title: string;
    session: SESSION;
}

export const programItems: ProgramItem[] = [
    { time: '09:45-10:00 CET', title: 'Opening', session: SESSION.MORNING },
    { time: '10:00-11:00 CET', title: 'Carmela Troncoso', session: SESSION.MORNING },
    { time: '11:00-11:15 CET', title: 'Break', session: SESSION.MORNING },
    { time: '11:15-12:15 CET', title: 'Mira Mezini', session: SESSION.MORNING },
    { time: '14:00-15:00 CET', title: 'Erich Gamma', session: SESSION.AFTERNONOON },
    { time: '15:00-15:15 CET', title: 'Break', session: SESSION.AFTERNONOON },
    { time: '15:15-16:15 CET', title: 'Bryan Ford', session: SESSION.AFTERNONOON },
    { time: '16:15-16:30 CET', title: 'Closing', session: SESSION.AFTERNONOON },
];