import React from 'react';
import { Flex, Image, Text, ThemeUIStyleObject } from 'theme-ui';
import codeLoungeLogo from '../../images/CodeLoungeLogo-white.png';

interface PoweredByProps {
  sx?: ThemeUIStyleObject;
  lastModified: string;
}

export const PoweredBy = ({ sx, lastModified }: PoweredByProps) => {
  return (
    <Flex sx={{ flexDirection: 'column', ...sx }}>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: ['column', null],
        }}
      >
        <Text color="white">Powered by </Text>
        <a href="https://codelounge.si.usi.ch" target="_blank">
          <Image src={codeLoungeLogo} sx={{ height: '5rem' }} />
        </a>
      </Flex>
      <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Text color="darkgrey">last modified on {lastModified}</Text>
      </Flex>
    </Flex>
  );
};
