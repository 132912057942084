import { Speaker } from '../models/Speaker';
import carmelaTroncoso from './carmela-troncoso.jpg';
import miraMezini from './mira-mezini.jpg';
import bryanFord from './bryan-ford.jpg';
import erichGamma from './erich-gamma.jpg';

export const speakers: Speaker[] = [
  {
    name: 'Carmela Troncoso',
    image: carmelaTroncoso,
    position: 'Assistant Professor',
    affiliation: 'EPFL',
    website: 'http://carmelatroncoso.com/',
    bio: 'Carmela Troncoso is an Assistant Professor at EPFL (Switzerland), where she heads the SPRING (Security and Privacy Engineering) Lab. Before arriving to EPFL she was a Faculty member at the IMDEA Software Institute (Spain) for 2 years. Carmela\'s research focuses on security and privacy. Her thesis "Design and Analysis methods for Privacy Technologies" (KU Leuven, 2011) received the European Research Consortium for Informatics and Mathematics Security and Trust Management Best Ph.D. Thesis Award; and her work on Privacy Engineering received the CNIL-INRIA Privacy Protection Award 2017.',
    talkTitle: 'Privacy engineering in an agile world',
    talkVideoLink: 'https://youtu.be/sF_pECNlvCw',
    talkAbstract: 'In this talk, we will discuss the complexity of engineering privacy into systems and products when the software development cycle is agile and the products have to rely on services. To this end, we will revisit challenges and lessons learned in our work on privacy engineering for contact tracing apps in the context of the COVID-19 pandemic.',
  },
  {
    name: 'Mira Mezini',
    image: miraMezini,
    position: 'Professor',
    affiliation: 'Technische Universitat Darmstadt',
    website:
      'https://www.stg.tu-darmstadt.de/main_stg/staff_stg/mira_mezini_1.en.jsp',
    bio: 'Mira Mezini is a full professor of Computer Science at the Technical University of Darmstadt and leads the Software Technology Group at the Department of Computer Science. She is member of the board of the National Research Center for Applied Cyber Security ATHENE, spokesperson of hessian.AI (the Hessian Center for Artificial Intelligence), and a member of the German Academy of Science and Engineering (acatech). Her current research focuses on Programming paradigms and languages for resilient decentralized data-intensive software systems, code intelligence (learning program analyses for automated software quality), and development methods for AI software systems.',
    talkTitle: 'Programming Distributed Decentralized Applications',
    talkVideoLink: 'https://youtu.be/TdTo8vTzGME',
    talkAbstract: 'Today’s applications - ranging from mobile digital services (instant messengers, multiplayer games, etc.), collaborative workflows (Google Docs, Figma, Trello, etc.), real-time businesses (cross-organizational workflows, collaborative condition monitoring, etc.), autonomous vehicle services, production 4.0 software, and more - are very different from traditional ones: They run on globally distributed computing infrastructures, consistently interact with the outside world and are data-driven. In this talk, I will argue that to cope with challenges posed by these new computing realities we need programming models and technologies that offer safety and security properties by-design. More specifically, I will talk about language-integrated solutions that offer formally-proven consistency guarantees “out-of-the-box” in a decentralized and privacy-preserving manner and will present the ideas in the context of [REScala](https://www.rescala-lang.com) - a library-based Scala extension.',
  },
  {
    name: 'Erich Gamma',
    image: erichGamma,
    position: 'Technical Fellow',
    affiliation: 'Microsoft Zurich',
    website: 'https://github.com/egamma',
    bio: 'Erich Gamma is a Microsoft Technical Fellow. He works with his team in Zurich on Visual Studio Code. Previously he worked for IBM and he was one of the fathers of Eclipse and was leading the Eclipse Java development tools. Erich is also a member of the Gang of Four, which is known for its classical book, Design Patterns - Elements of Reusable Object-Oriented Software.',
    talkTitle: 'Visual Studio Code an Overnight Success… 10 years in the making',
    talkVideoLink: 'https://youtu.be/PMCvTL_kscI',
    talkAbstract: 'Visual Studio Code has seen some landslide victories in recent developer surveys. The latest being a 71% share in Stackoverflow\'s 2021 results. What is easily forgotten is that this product was over 10 years in the making and it is since 5 years a successful Open Source project. In this talk I will look into the evolution of VS Code and will shed some light into how we develop VS Code in the Open.'
  },
  {
    name: 'Bryan Ford',
    image: bryanFord,
    position: 'Associate Professor',
    affiliation: 'EPFL',
    website: 'https://bford.info/',
    bio: 'Prof. Bryan Ford leads the Decentralized/Distributed Systems (DEDIS) research laboratory at the Swiss Federal Institute of Technology in Lausanne (EPFL). Ford\'s research focuses on decentralized systems, security and privacy, digital democracy, and blockchain technology. Since earning his Ph.D.at MIT, Ford has held faculty positions at Yale University and EPFL. His awards include the Jay Lepreau Best Paper Award, the NSF CAREER award, and the AXA Research Chair. Inventions he is known for include parsing expression grammars, delegative or liquid democracy, and scalable sharded blockchains. He has advised numerous companies and governments, including serving on the US DARPA Information Science and Technology(ISAT) Study Group and on the Swiss Federal E-voting Experts Dialog.',
    talkTitle: 'Digital Personhood: Towards Technology that Securely Serves People',
    talkVideoLink: 'https://youtu.be/oUlicBgkGyU',
    talkAbstract: `Internet-based distributed software systems, social-media platforms, and most recently blockchain systems, have often been called "democratizing” - by virtue of giving anyone a voice and stake in countless online communities.  These technologies cannot actually be "democratizing" by basic democratic principles, however, unless they serve everyone, offer everyone not just a voice but an equal voice, and are accountable to and ultimately governed by the people they serve.  Today's social technologies usually offer not democracy but guardianship, subjecting our online lives to the arbitrary oversight of unelected employees, committees, platforms, and algorithms, which serve profit motives or special interests over our broader interests.  Can we build genuinely democratizing software systems that serve their users inclusively, equally, and securely?  
    A necessary first step is digital personhood: enabling technology to distinguish between real people and fake accounts such as sock puppets, bots, or deep fakes.  Digital identity approaches undermine privacy and threaten our effective voice and freedoms, however, both in existing real-world democracies and in online forums that we might wish to embody democratic ideals.  An emerging ecosystem of "proof of personhood" schemes attempts to give willing participants exactly one credential each while avoiding the privacy risks of digital identity.  Proof of personhood schemes may operate in the physical world or online, building on security foundations such as in-person events, biometrics, social trust networks, and Turing tests.  We will explore the promise and challenges of secure digital personhood, and the tradeoffs of different approaches along the key metrics of security, privacy, inclusion, and equality.  We will cover further security challenges such as resisting collusion, coercion, or vote-buying.  Finally, we will outline a few applications of secure digital personhood, both already prototyped and envisioned.`
  },
];
