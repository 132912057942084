import type { Theme } from 'theme-ui';

export const theme: Theme = {
  colors: {
    footerBg: '#222',
  },
  fonts: {
    body: 'AkzidenzGroteskBE, sans-serif',
    heading: 'AkzidenzGroteskBE, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontWeights: {}, // TODO should be defined, for interoperability
  lineHeights: {}, // TODO should be defined, for interoperability
  text: {
    default: {
      fontFamily: 'body',
      color: 'grey',
      fontSize: 3,
      fontWeight: 300,
    },
    h1: {
      fontFamily: 'heading',
      color: 'black',
      fontSize: 6,
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'heading',
      color: 'black',
      fontSize: 5,
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'heading',
      color: 'black',
      fontSize: 4,
      fontWeight: 700,
    },
    link: {
      color: 'black',
      cursor: 'pointer',
      textDecoration: 'underline',
      ":hover": {
        textDecoration: 'none',
      }
    },
  },
  buttons: {
    primary: {
      color: 'white',
      bg: '#222',
      '&:hover': {
        color: 'black',
        bg: 'white',
        border: '1px solid black'
      },
      cursor: 'pointer'
    }
  },
  space: [
    '0.25rem',
    '0.5rem',
    '1rem',
    '2rem',
    '3rem',
    '4rem',
    '5rem',
    '6rem',
    '8rem',
    '10rem',
    '12rem',
    '15rem',
  ],
  breakpoints: [
    '758px', '992px', '1400px'
  ],
};
