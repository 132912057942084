import {
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Flex, Link, ThemeUIStyleObject } from 'theme-ui';
import SwissUniversitiesLogo from '../../images/swissuniversitiesLogo-white.svg';

interface SwissuniversitiesProps {
  sx?: ThemeUIStyleObject;
}

export const Swissuniversities = ({ sx }: SwissuniversitiesProps) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: ['center', 'flex-end'],
        ...sx,
      }}
    >
      <Flex
        sx={{
          maxHeight: '5rem',
          width: '100%',
          maxWidth: '15rem',
          flexDirection: 'column',
        }}
      >
        <SwissUniversitiesLogo />
        <Flex sx={{ justifyContent: 'space-between', pt: '1rem' }}>
          <Link href="https://twitter.com/usisoftware" target="_blank">
            <FontAwesomeIcon icon={faTwitter} size="lg" color="white" />
          </Link>
          <Link href="https://facebook.com/usisoftware" target="_blank">
            <FontAwesomeIcon icon={faFacebook} size="lg" color="white" />
          </Link>
          <Link href="https://instagram.com/usisoftware" target="_blank">
            <FontAwesomeIcon icon={faInstagram} size="lg" color="white" />
          </Link>
          <Link
            href="https://www.youtube.com/channel/UC4Pq8i8UP71E5Zv94sg8FHg"
            target="_blank"
          >
            <FontAwesomeIcon icon={faYoutube} size="lg" color="white" />
          </Link>
          <Link
            href="https://www.linkedin.com/school/usisoftware"
            target="_blank"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" color="white" />
          </Link>
          <Link href="https://github.com/USI-INF-Software" target="_blank">
            <FontAwesomeIcon icon={faGithub} size="lg" color="white" />
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};
