import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { PageSection } from '../section-templates/PageSection';
import { ProgramItem, programItems, SESSION } from './program/program';
import { useBreakpointIndex } from '@theme-ui/match-media';

export const Program = () => {
  const responsiveBreakpointIndex = useBreakpointIndex();
  return (
    <PageSection>
      <PageSection.Title>Program</PageSection.Title>
      <PageSection.Content>
        <Flex
          sx={{
            justifyContent: 'center',
            gap: [1, 5],
            flexDirection: ['column', 'row'],
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: ['center', 'flex-end'],
              pb: 3,
            }}
          >
            <DateRow text="Thursday" />
            <DateRow text="17" />
            <DateRow text="February" />
            <DateRow text="2022" />
          </Flex>
          <Flex
            sx={{
              flexDirection: 'column',
            }}
          >
            <Box sx={{ mb: 3 }}>
              {programItems
                .filter((item) => item.session === SESSION.MORNING)
                .map((item) => (
                  <ProgramRow
                    programItem={item}
                    isSmallDevice={responsiveBreakpointIndex === 0}
                    key={item.time}
                  />
                ))}
            </Box>
            {programItems
              .filter((item) => item.session === SESSION.AFTERNONOON)
              .map((item) => (
                <ProgramRow
                  programItem={item}
                  isSmallDevice={responsiveBreakpointIndex === 0}
                  key={item.time}
                />
              ))}
          </Flex>
        </Flex>
      </PageSection.Content>
    </PageSection>
  );
};

const ProgramRow = ({
  programItem,
  isSmallDevice,
}: {
  programItem: ProgramItem;
  isSmallDevice: boolean;
}) => {
  return isSmallDevice ? (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        my: 3,
        borderBottom: '1px solid black',
      }}
    >
      <Text sx={{ fontSize: 2 }}>{programItem.time}</Text>
      <Text color="black">{programItem.title}</Text>
    </Flex>
  ) : (
    <Flex>
      <Box sx={{ px: 1, pb: 1 }}>
        <Text sx={{ fontSize: 2 }}>{programItem.time}</Text>
      </Box>
      <Box sx={{ px: 1 }}>
        <Text color="black">{programItem.title}</Text>
      </Box>
    </Flex>
  );
};

const DateRow = ({ text }: { text: string }) => {
  return (
    <Text color="black" sx={{ fontSize: [4, 4, 5], fontWeight: 'bold' }}>
      {text}
    </Text>
  );
};
