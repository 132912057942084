/**
 * this component allows to reuse the same styling in both cases when you'd use the gatsby Link for links to this website and when you'd use the themeUI link to external websites.
 * you just need to provide an extra prop i.e., external
 */

import { Link as ThemeUiLink } from '@theme-ui/components';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { LinkProps, ThemeUIStyleObject } from 'theme-ui';

interface Props extends LinkProps {
  external?: boolean;
  children: React.ReactNode;
}

const linkStyles: ThemeUIStyleObject = {
  color: 'black',
  cursor: 'pointer',
  textDecoration: 'underline',
  ':hover': { textDecoration: 'none' },
};

export const Link = ({ external = false, children, ...rest }: Props) => {
  return external ? (
    <ThemeUiLink sx={linkStyles} {...rest}>
      {children}
    </ThemeUiLink>
  ) : (
    <ThemeUiLink sx={linkStyles} {...rest} as={GatsbyLink}>
      {children}
    </ThemeUiLink>
  );
};
