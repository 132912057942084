import React, { useEffect, useState } from 'react';
import { Flex, Image, Text } from 'theme-ui';
import { useBreakpointIndex } from '@theme-ui/match-media';
import siLogoLg from '../images/si-logo-lg.jpg';
import siLogoSm from '../images/si-logo-sm.jpg';

const navBarHeightSm = '7rem';
const navBarHeightLg = '9rem';

export const NavBar = () => {
  const responsiveBreakpointIndex = useBreakpointIndex();

  const [navbarHeight, setNavbarHeight] = useState<string>(navBarHeightLg);

  const updateNavBarHeight = () => {
    if (window.scrollY > 0) {
      setNavbarHeight(navBarHeightSm);
    } else {
      setNavbarHeight(navBarHeightLg);
    }
  };

  const sources = [siLogoSm, siLogoLg, siLogoLg, siLogoLg];

  useEffect(() => {
    updateNavBarHeight();
    return () => {
      setNavbarHeight(navBarHeightLg);
    };
  }, []);

  if (typeof window !== 'undefined')
    window.addEventListener('scroll', updateNavBarHeight);

  return (
    <Flex
      sx={{
        position: 'sticky',
        top: '0px',
        height: navbarHeight,
        background: 'white',
        borderBottom: '1px solid black',
        transition: 'all .3s ease-in-out',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          p: '1rem',
          pr: [null, null, '5rem'],
        }}
      >
        <Image
          src={sources[responsiveBreakpointIndex]}
          sx={{
            p: [null, '0.5rem', '0.5rem'],
            display: 'block',
            maxHeight: ['5rem', '5rem', '5rem'],
            height: 'auto',
            width: 'auto',
          }}
        />
      </Flex>
      <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Flex
          sx={{
            justifyContent: 'flex-start',
            flexDirection: 'column',
          }}
        >
          <Text variant="h2">SI Summit 2022</Text>
          <Text variant="h3" color="gray" sx={{ alignSelf: 'flex-start' }}>
            17 February 2022
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
