import React from 'react';
import { Flex, Image } from 'theme-ui';
import sansalvatoreImage from '../../images/si-summit-sansalvatore-code.jpg';
import siSummitLogo from '../../images/si-summit-logo.png';

export const MainImage = () => {
  return (
    <Flex
      sx={{
        height: '80vh',
        backgroundImage: `url(${sansalvatoreImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
      }}
    >
      <Image
        src={siSummitLogo}
        sx={{
          mr: ['1rem', '1rem', '1.5rem'],
          mb: ['2rem', '2rem', '2.5rem'],
          display: 'block',
          maxHeight: ['5rem', '5rem', '8rem'],
          height: 'auto',
          width: 'auto',
        }}
      />
    </Flex>
  );
};
