import React from 'react';
import { Flex, Text } from 'theme-ui';
import { Link } from '../commons/Link';
import { PageSection } from '../section-templates/PageSection';

export const RegistrationClosed = () => {
  return (
    <PageSection>
      <PageSection.Title>
        See you in 2023 for the second SI Summit!
      </PageSection.Title>
      <PageSection.Content>
        <Flex sx={{ witdh: '100%', justifyContent: 'center' }}>
          <Text>
            To stay up to date about our events, follow us on{' '}
            <Link
              external
              href="https://twitter.com/usisoftware"
              target="_blank"
            >
              Twitter
            </Link>
          </Text>
        </Flex>
      </PageSection.Content>
    </PageSection>
  );
};
