import { Link } from 'gatsby';
import React from 'react';
import { Box, Flex, ThemeUIStyleObject } from 'theme-ui';
import { SpeakerDetail } from './SpeakerDetail';

interface Props {
  name: string;
  image: string;
  position: string;
  affiliation: string;
  website: string;
  sxProps?: ThemeUIStyleObject;
}

export const ImageAndDetails = ({
  name,
  image,
  position,
  affiliation,
  website,
  sxProps,
}: Props) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
    <Flex
      sx={{
        width: '80vw',
        maxWidth: '350px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        ...sxProps,
      }}
    >
      <Box
        sx={{
          width: '100%',
          paddingBottom: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% auto',
          backgroundImage: `url(${image})`,
          cursor: 'pointer',
        }}
        onClick={goToWebsite(website)}
      />
      <SpeakerDetail
        text={name}
        textColor="white"
        bgColor="black"
        textProps={{ fontWeight: 'bold' }}
      />
      <SpeakerDetail text={position} textColor="white" bgColor="grey" />
      <SpeakerDetail
        text={affiliation}
        textColor="black"
        bgColor="white"
        sx={{ borderBottom: '1px solid grey' }}
      />
    </Flex>
  </Box>
);

const goToWebsite = (url: string) => (): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};
