import React from 'react';
import { Box, Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import { Markdown } from '../../../commons/Markdown';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '../../../commons/Link';

interface Props {
  bio?: string;
  talkTitle?: string;
  talkVideoLink?: string;
  talkAbstract?: string;
  sxProps?: ThemeUIStyleObject;
}

export const AbstractAndBio = ({
  bio,
  talkTitle,
  talkVideoLink,
  talkAbstract,
  sxProps,
}: Props) => {
  const titleBoxStyles: ThemeUIStyleObject = {
    pb: 1,
  };
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        ...sxProps,
      }}
    >
      {talkTitle && talkAbstract && (
        <>
          <Box sx={{ ...titleBoxStyles }}>
            <Text variant="h3">{talkTitle}</Text>
          </Box>
          {talkVideoLink && (
            <a href={talkVideoLink} target="_blank">
              <Box sx={{ ...titleBoxStyles }}>
                <FontAwesomeIcon icon={faYoutube} size="lg" color="red" />
                <Text
                  variant="link"
                  sx={{
                    marginLeft: 1,
                  }}
                >
                  Watch the talk
                </Text>
              </Box>
            </a>
          )}
          <Markdown text={talkAbstract} />
        </>
      )}
      {bio && (
        <>
          <Box sx={{ ...titleBoxStyles, mt: 3 }}>
            <Text variant="h3">Bio</Text>
          </Box>
          <Markdown text={bio} />
        </>
      )}
    </Flex>
  );
};
