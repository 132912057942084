import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { speakers } from '../../data/speakers';
import { PageSection } from '../section-templates/PageSection';
import { SpeakerItem } from './speakers/SpeakerItem';

const IMAGE_ONLY = false; // change this to hide or show the column with the talk abstract and the speaker bio

export const Speakers = () => {
  const styles: ThemeUIStyleObject = IMAGE_ONLY
    ? {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr'],
        columnGap: 5,
        rowGap: 5,
      }
    : { display: 'flex', flexDirection: 'column', gap: [9, 9, 11] };
  return (
    <PageSection>
      <PageSection.Title>Talks</PageSection.Title>
      <PageSection.Content>
        <Box sx={{ ...styles }}>
          {speakers.map((speaker, index) => (
            <SpeakerItem
              key={speaker.name}
              speaker={speaker}
              reverse={Boolean(index % 2)}
              imageOnly={IMAGE_ONLY}
            />
          ))}
        </Box>
      </PageSection.Content>
    </PageSection>
  );
};
