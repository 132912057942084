import React from 'react';

import { graphql } from 'gatsby';
import { SiteMetadataLookupQuery } from '../../types/graphql-types';

import MainPage from '../components/Main';
// import 'pages/index.module.css';

import { ThemeProvider } from 'theme-ui';
import { theme } from '../theme/theme';
import { SiteHelmet } from '../components/SiteHelmet';

interface IPageProps {
  data: SiteMetadataLookupQuery;
}

const IndexPage = ({ data }: IPageProps) => {
  const siteMetadata = data.site && data.site.siteMetadata;

  return (
    <ThemeProvider theme={theme}>
      <SiteHelmet siteMetadata={siteMetadata} />
      <MainPage />
    </ThemeProvider>
  );
};

export const pageQuery = graphql`
  query SiteMetadataLookup {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`;
export default IndexPage;
