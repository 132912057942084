import React from 'react';
import Helmet from 'react-helmet';
import { Maybe, SiteSiteMetadata } from '../../types/graphql-types';
import image from '../images/si-summit-banner.jpg';
import favicon from '../images/favicon.ico';

interface IProps {
  siteMetadata: Maybe<Pick<SiteSiteMetadata, 'title' | 'description' | 'url'>>;
}

export const SiteHelmet = ({ siteMetadata }: IProps) => {
  const getSiteMetaDataProperty = (
    key: keyof Pick<SiteSiteMetadata, 'title' | 'description' | 'url'>
  ) => {
    if (siteMetadata && key in siteMetadata) {
      return siteMetadata[key] || undefined;
    }

    return undefined;
  };

  return (
    <Helmet
      title={getSiteMetaDataProperty('title')}
      link={[
        { href: getSiteMetaDataProperty('url') },
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
      ]}
      meta={[
        {
          name: 'title',
          content: getSiteMetaDataProperty('title'),
        },
        {
          name: 'description',
          content: getSiteMetaDataProperty('description'),
        },

        // facebook meta tags
        { property: 'og:url', content: getSiteMetaDataProperty('url') },
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: getSiteMetaDataProperty('title') },
        {
          property: 'og:description',
          content: getSiteMetaDataProperty('description'),
        },
        {
          property: 'og:image',
          content: `${getSiteMetaDataProperty('url')}${image}`,
        },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        {
          property: 'og:image:alt',
          content: getSiteMetaDataProperty('title'),
        },
        // twitter meta tags
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:domain', content: getSiteMetaDataProperty('url') },
        { property: 'twitter:url', content: getSiteMetaDataProperty('url') },
        { name: 'twitter:title', content: getSiteMetaDataProperty('title') },
        {
          name: 'twitter:description',
          content: getSiteMetaDataProperty('description'),
        },
        {
          property: 'twitter:image',
          content: `${getSiteMetaDataProperty('url')}${image}`,
        },
      ]}
    />
  );
};
