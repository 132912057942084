import { OrganizationCommitteeMember } from '../models/OrganizationCommitteeMember';
import caf from './caf.jpg';
import am from './am.jpg';
import rm from './rm.jpg';
import mp from './mp.jpg';
import el from './el.jpg';
import fb from './fb.jpg';

export const members: OrganizationCommitteeMember[] = [
  {
    name: ' Carlo Alberto Furia ',
    image: caf,
    position: 'Program Co-Chair',
  },
  {
    name: 'Andrea Mocci',
    image: am,
    position: 'Program Co-Chair',
  },
  {
    name: 'Roberto Minelli',
    image: rm,
    position: 'Publicity and Remote Chair',
  },
  {
    name: 'Elisa Larghi',
    image: el,
    position: 'Finance and Local Chair',
  },
  {
    name: ' Mauro Prevostini ',
    image: mp,
    position: 'Industry Liaisons',
  },
  {
    name: 'Francesco Bresciani',
    image: fb,
    position: 'Web Chair',
  },
];
