import React from 'react';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

interface Props {
  sx?: ThemeUIStyleObject;
  text: string;
  textColor: string;
  bgColor: string;
  textProps?: ThemeUIStyleObject;
}

export const SpeakerDetail = ({
  sx,
  text,
  textColor,
  bgColor,
  textProps,
}: Props) => (
  <Flex
    backgroundColor={bgColor}
    sx={{ width: '100%', alignItems: 'center', p: 1, ...sx }}
  >
    <Text color={textColor} sx={{ width: '100%', ...textProps }}>
      {text}
    </Text>
  </Flex>
);
