import React from 'react';
import { Flex, Text } from 'theme-ui';

interface TextOnlyProps {
  children: React.ReactNode;
}

export const PageSection = ({ children }: TextOnlyProps) => {
  return (
    <section>
      <Flex
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          mt: [8, 8, 11],
          mb: [6, 6, 9],
          px: [3, 6, '10vw', '15vw'],
        }}
      >
        {children}
      </Flex>
    </section>
  );
};

interface TextOnlyTitleProps {
  children: React.ReactNode;
}

PageSection.Title = ({ children }: TextOnlyTitleProps) => {
  return (
    <Flex sx={{ justifyContent: 'center' }}>
      <Text
        variant="h1"
        sx={{
          pt: 2,
          pb: 4,
        }}
      >
        {children}
      </Text>
    </Flex>
  );
};

interface TextOnlyContentProps {
  children: React.ReactNode;
}

PageSection.Content = ({ children }: TextOnlyContentProps) => {
  return <Text>{children}</Text>;
};
