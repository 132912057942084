import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Box, Text } from 'theme-ui';
import { Address } from './footer/Address';
import { PoweredBy } from './footer/PoweredBy';
import { Swissuniversities } from './footer/Swissuniversities';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const Footer = () => {
  const [lastModified, setLastModified] = useState<string>('');

  useEffect(() => {
    setLastModified(new Date(document.lastModified).toLocaleDateString());
  }, []);

  return (
    <Box sx={{ backgroundColor: 'footerBg', px: 3, py: 5 }}>
      <Box
        sx={{
          display: 'grid',
          flex: 1,
          justifyContent: ['center', 'space-between', 'space-between', 'unset'],

          gridTemplateColumns: ['unset', 'unset', 'unset', '1fr 1fr 1fr'],
          gridTemplateAreas: [
            '"addr" "swiun" "powby"',
            '"addr swiun" "powby powby"',
            '"addr swiun" "powby powby"',
            'unset',
            ,
          ],
          rowGap: [9, 7, 7, null],
        }}
      >
        <Address sx={{ gridArea: ['addr', 'addr', 'addr', 'unset'] }} />
        <PoweredBy
          sx={{ gridArea: ['powby', 'powby', 'powby', 'unset'] }}
          lastModified={lastModified}
        />
        <Swissuniversities
          sx={{ gridArea: ['swiun', 'swiun', 'swiun', 'unset'] }}
        />
      </Box>
      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Text variant="h2" color="white">
          Questions?
        </Text>
        <a href="mailto:si.summit@usi.ch">
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              ':hover': { borderBottom: '1px solid lightgray' },
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} size="lg" color="lightgray" />
            <Text variant="h3" color="lightgray">
              si.summit@usi.ch
            </Text>
          </Box>
        </a>
      </Box>
    </Box>
  );
};
