import React from 'react';

import { NavBar } from './NavBar';
import { Footer } from './Footer';
import { Speakers } from './main-page-sections/Speakers';
import { MainImage } from './main-page-sections/MainImage';
import { Event } from './main-page-sections/Event';
import { OrganizationCommittee } from './main-page-sections/OrganizationCommittee';

import { Program } from './main-page-sections/Program';
import { RegistrationClosed } from './main-page-sections/RegistrationClosed';

const MainPage = () => (
  <>
    <NavBar />
    <main style={{ overflowX: 'hidden' }}>
      <section>
        <MainImage />
      </section>
      <Event />
      <Speakers />
      <Program />
      <RegistrationClosed />
      <OrganizationCommittee />
    </main>
    <Footer />
  </>
);

export default MainPage;
