import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { OrganizationCommitteeMember } from '../../../models/OrganizationCommitteeMember';

interface Props {
  member: OrganizationCommitteeMember;
}

export const MemberItem = ({ member }: Props) => {
  const { name, image, position } = member;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
      <Flex
        sx={{
          width: '80vw',
          maxWidth: ['350px', '250px', '250px'],
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: '100%',
            paddingBottom: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
            backgroundImage: `url(${image})`,
          }}
        />
        <Flex
          backgroundColor="black"
          sx={{ width: '100%', alignItems: 'center', p: 1 }}
        >
          <Text color="white" sx={{ width: '100%', textAlign: 'center' }}>
            {name}
          </Text>
        </Flex>
        <Flex
          backgroundColor="white"
          sx={{
            width: '100%',
            p: 1,
            borderBottom: '1px solid grey',
          }}
        >
          <Text
            color="black"
            sx={{
              width: '100%',
              fontWeight: 'bold',
              minHeight: '2em',
              textAlign: 'center',
            }}
          >
            {position}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
