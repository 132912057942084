import React from 'react';
import { Box, ThemeUIStyleObject } from 'theme-ui';
import { members } from '../../data/members';
import { PageSection } from '../section-templates/PageSection';
import { MemberItem } from './organization-committee/MemberItem';

export const OrganizationCommittee = () => {
  const styles: ThemeUIStyleObject = {
    display: ['grid', 'flex', 'flex'],
    flexFlow: [null, 'row wrap', 'row wrap'],
    justifyContent: 'center',
    width: '100%',
    gridTemplateColumns: ['1fr', null, null],
    columnGap: 5,
    rowGap: 5,
  };

  return (
    <PageSection>
      <PageSection.Title>Organization Committee</PageSection.Title>
      <PageSection.Content>
        <Box sx={{ ...styles }}>
          {members.map((member) => (
            <MemberItem key={member.name} member={member} />
          ))}
        </Box>
      </PageSection.Content>
    </PageSection>
  );
};
